@import "@speed/common/src/assets/styles/common.scss";

@mixin boxBorder {
  width: 100%;
  border-radius: 4px;
}

@mixin toast {
  font-family: $fontRegular;
  font-weight: 500;
}

@mixin switchAccountIcon {
  .switch-account-icon {
    width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
    padding: 6px;
    border-radius: 15px;
    background-color: $greyText;
    color: $white;

    svg {
      font-size: $font18;
    }
  }
}

.mobile-balance {
  display: flex;
  justify-content: center;
  margin: auto;
}

.desktop-balance {
  padding-left: 110px;
}

.appbar-header-isloggedIn {
  height: 85px !important;
}

.header-remove-border {
  border-bottom: none !important;
}

.layout-box {
  display: flex;
  margin: 0 10px 0;
}

body {
  margin: 0;
}

.disabled-tab-data {
  .MuiTabPanel-root {
    background-color: $lightAliceBlue !important;
  }
}

.input-box {
  @include boxShadow();
  @include boxBorder();
}

.tab-data-lndev {
  width: 100%;
  padding-top: 20px;

  .MuiTab-textColorPrimary {
    text-transform: none !important;
  }

  .MuiTabPanel-root {
    background-color: $white;
    height: auto;
    padding: 0 !important;
  }

  .MuiTabs-root .MuiButtonBase-root.Mui-selected {
    background-color: $white;
    color: $primaryBlue;
  }

  .MuiTabs-root .MuiTabs-flexContainer {
    display: flex;
    justify-content: center;
    padding: 0% !important;
  }
}

.lndev-content-data {
  padding: 0 29%;
}

.send-box {
  width: 100%;
  border: solid 1px $lightCyanBlue;
  border-radius: 4px;
  @include boxShadow();
  height: 215px;
}

.signIn-box {
  width: 100%;
  border: solid 1px $lightCyanBlue;
  border-radius: 4px;
  @include boxShadow();
  height: 240px;
}

.closeIcon {
  padding-left: 101%;
}

.lightning-logo {
  padding: 10px 0 0 15px;
}

.focused {
  border: 3px solid $topicalBlue;
  border-radius: 4px;
}

.default-text {
  color: $greyText !important;
  font-weight: normal;
  font-family: $fontSemiBold;
}

.payment-request {
  .MuiInputBase-root {
    width: 100%;
  }
}

.lndev-payment-btn {
  padding-top: 5%;
  display: flex;
  justify-content: center;
}

.send-payment-text {
  color: $darkIndigo;
  font-weight: 500;

  .MuiTypography-root {
    font-family: $fontSemiBold;
  }
}

.send-amount-input {
  width: 156px;

  .MuiInputBase-input {
    padding: 7px 12px !important;
  }
}

.lndev-receive-payment-request {
  @include boxBorder();
  @include boxShadow();
  background-color: $paleGray;
}

.show-receive-QR {
  height: 408px;
}

.hide-receive-QR {
  height: 138px;
}

.lndev-receive-payment {
  padding: 20px 25px 0 25px;

  .clipboard-link-receive {
    min-width: calc(100% - 30px);
    padding-right: 20px;

    >div {
      >div:first-child {
        flex: 1;
      }
    }
  }
}

.send-payment-request {
  margin: 20px;
  height: 131px;
  padding: 0 25px 23px 25px;
  border-radius: 4px;
  border: solid 1px $lightCyanBlue;
  background-color: $paleGray;
}

.without-login-box {
  height: 150px !important;
}

.footer {
  margin-top: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  justify-content: space-between;
}

.lndev-input {
  .MuiInputBase-input {
    box-shadow: none !important;
  }
}

.lndev-input.Mui-focused {
  outline: none !important;
}

.flex-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lndev-content {
  padding: 12% 12%;
  margin: auto;
}

.toast-send-messages {
  padding: 20px 0 0;
  @include boxBorder();
  border: solid 1px $primaryBlue;
  margin-top: 39px;
}

.toast-loader {
  background-color: $paleGray;
  border: solid 1px $lightCyanBlue;
}

.users-vertical-data {
  height: 80px;
  margin-left: 21px;

  .MuiTableCell-root {
    border: none;
    padding: 12px !important;
  }

  .MuiTableCell-head {
    width: 150px;
  }
}

.viewMore-ln-send-message {
  height: 19px;
  margin: 0 10px 0 0;
  font-size: $font16 !important;
  color: $greyText;
  cursor: pointer;
}

.show-less-payment {
  height: 156px;
}

.view-more-text-icon {
  height: 20px;
  width: 20px;
}

.lndev-logo {
  height: 150px;
  padding-left: 145px !important;
}

.secured-by-footer {
  @include contentCenter();
}

#header-account-select {
  padding-right: 12px !important;
}

.header {
  &.user-in {
    .MuiContainer-root {
      >.MuiPaper-root {
        .MuiToolbar-root {
          .switch-label {
            margin-right: 0;

            .MuiTypography-root {
              font-size: $font12;
              color: $darkIndigo;
              font-family: $fontMedium;
              margin-right: 9px;
            }
          }
        }

        .MuiBadge-root {
          margin: 4px 25px 3px 0px;

          &:focus-visible {
            @include applyFocusBorder();
          }
        }
      }

      .header-account-select {

        &::before,
        &::after {
          border-bottom: none;
        }

        .MuiSelect-select {
          &:focus {
            background: none;
          }

          .MuiMenuItem-root {
            padding-left: 0;
            @include switchAccountIcon();

            &:hover {
              background: none;
            }

            .MuiTypography-root {
              font-size: $font16;
              max-width: 100px;
            }
          }
        }

        >.MuiSvgIcon-root {
          position: absolute;
          right: 7px;
          pointer-events: none;
        }
      }
    }
  }
}

#header-account-select-menu {
  .MuiPopover-paper {
    max-height: 534px;
    width: 238px;
    top: 10px !important;
    margin-left: 0;
    box-shadow: 0 4px 7px 3px rgba(11, 37, 75, 0.12);
    @include applyBorder($lightCyanBlue);

    .MuiList-root {
      padding: 0px !important;

      .MuiMenuItem-root {
        &.Mui-selected {
          background-color: $lightBlue !important;
        }

        &.Mui-focusVisible,
        &.Mui-focused {
          background-color: $backgroundHover;
        }

        @include switchAccountIcon();
        height: 40px;
        margin-bottom: 10px;

        &.Mui-selected:hover {
          .MuiBox-root {
            max-width: calc(100% - 65px) !important;
          }

          .edit-account-icon {
            display: block;
            position: absolute;
            right: 10px;

            &:focus-visible {
              @include applyFocusBorder();
            }

            &:hover {
              color: $darkIndigo;
            }
          }
        }

        .MuiTypography-root {
          font-family: $fontMedium;
        }

        .edit-account-icon {
          display: none;
          color: $greyText;

          svg {
            display: block;
            font-size: $font16;
          }
        }

        &:last-child {
          margin-bottom: 5px;
        }
      }

      .add-new-acccount-box-wrapper {
        cursor: pointer;
        height: 56px;
        background-color: $paleGray;
        padding: 0;
        border-top: solid 1px $lightCyanBlue;
        margin-bottom: 0;

        .add-new-acccount-box {
          display: flex;
          align-items: center;
          padding: 9.5px 16px;
          margin: 3px;

          &:focus-visible {
            @include applyFocusBorder();
          }

          .add-new-account-icon {
            width: 30px;
            height: 30px;
            margin: 0 10px 0 0;
            padding: 4px 7px;
            border-radius: 15px;
            @include applyBorder(rgba(132, 139, 158, 0.5));

            svg {
              color: $greyText;
              font-size: $font14;
            }
          }
        }
      }
    }
  }

  .auto-complete {
    padding: 14px 5px 0px 11px;

    .MuiAutocomplete-root {
      padding: 0px 9px 0px 3px;
      outline: none;

      .MuiInputBase-root {
        box-shadow: 0 0 0 1px rgba(11, 37, 75, 0.08);
        border: none;
        padding: 5px 15px;
        border-radius: 4px;
        font-size: $font14;
        height: 42px;

        input {
          position: relative;
          top: 1px;
        }

        &.Mui-focused {
          border: none;
        }
      }
    }

    .MuiAutocomplete-popper {
      max-height: 400px;
      border: none;
      box-shadow: none;
      padding: 0px;
      overflow: auto;
      position: unset !important;
      transform: translate(0px, 0px) !important;
      margin: 10px 0px !important;
      width: 215px !important;

      .MuiPaper-root {
        ::-webkit-scrollbar-track {
          box-shadow: none;
        }

        .MuiAutocomplete-listbox {
          padding: 6px 6px 0px 0px;
          max-height: 400px;
        }

        .MuiAutocomplete-loading {
          padding: 10px 20px;
        }
      }
    }
  }
}

.developer-account-popup {
  .MuiPaper-elevation {
    width: 384px;
    border-radius: 10px;
  }
}

.developer-account-desc {
  width: 316px;
  font-family: Roboto !important;
  font-size: $font16 !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75 !important;
  letter-spacing: normal;
  text-align: center;
  color: $darkIndigo;
}

.scan-qr-code {
  border-radius: 5px;
  border: solid 1px $greyText;
  width: 30px !important;
  height: 30px;
  margin: 0 0 0 12px;
  margin-left: auto;
}

.btnIcQrCode-logo {
  width: 23px;
  height: 23px;
  padding: 6px 0 0 6px;
}

.toast-message {
  padding: 19px 20px 20px;
  border-radius: 4px;
  margin-top: 30px;
}

.receive-toast {
  border: solid 1px #1fca00;
  background-color: #fafff9;
}

.already-paid-toast {
  background-color: #fff5f5;
  border: solid 1px #e72f2f;

  .toast-description-data {
    color: #e72f2f;
  }
}

.payment-description {
  height: 19px;
  margin: 15px 0 15px 21px;
  font-size: $font16;
  color: $greyText;
}

.amount-sent {
  height: 61px;
  margin: 15px 0 0;
  padding: 18px 0 18px 22px;
  border-radius: 4px;
  background-color: #f7f9ff;
  font-size: $font20;
  font-weight: 500;
  font-family: $fontRegular;
  color: $primaryBlue;
}

.toast-description-data {
  padding-left: 10px;
  @include toast();
}

.signIn-box-btn {
  padding: 12px !important;
  width: 100px;
}

.toast-amount-data {
  color: $greenishTeal;
  @include toast();
}

.send-amount {
  color: $primaryBlue;
  @include toast();
}

.send-payment {
  padding-left: 10px;
  font-family: $fontSemiBold;
  font-weight: 500;
}

.send-total-amount {
  font-family: $fontSemiBold;
  font-weight: 500;
  width: 600px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .send-box {
    height: 290px !important;
  }

  .send-total-amount {
    font-family: $fontSemiBold;
    font-weight: 500;
    width: 300px;
  }

  .lndev-content {
    padding: 110px 0;
  }

  .lndev-content-data {
    padding: 20px;
  }

  .lndev-logo {
    width: 240px !important;
    padding-left: 18% !important;
  }

  .tab-data-lndev {
    .MuiTabPanel-root {
      width: 372px;
    }

    .MuiTabs-root {
      width: 372px;

      .MuiTabs-flexContainer {
        padding-left: 100px;
      }
    }
  }

  .lndev-receive-payment-request {
    width: auto;
  }

  .hide-receive-QR {
    height: 151px;
  }

  .show-receive-QR {
    height: 420px;
  }

  .lndev-receive-payment-label {
    display: block;
  }

  .sats-amount-lndev-receive {
    padding-top: 10px;
  }

  .lndev-receive-payment-link {
    .MuiBox-root {
      width: 252px;
    }

    padding-top: 10px;
  }

  .send-payment-request {
    width: auto;
  }

  .send-request-data {
    text-align: center;
    display: block;
  }

  .send-payment-text {
    text-align: center;
  }

  .send-amount {
    text-align: center;
    padding-top: 10px;
  }

  .send-payment-type {
    text-align: center;
    padding-top: 10px;
  }

  .send-payment-btn {
    padding-top: 20px;
    padding-right: 0;
  }

  .toast-message {
    width: auto;
  }

  .toast-send-messages {
    width: auto;
  }

  .payment-description {
    width: 312px;
  }

  .amount-sent {
    width: auto;
    padding: 18px 317px 18px 15px;
  }

  .receive-toast {
    display: block;

    .toast-description {
      justify-content: left;
    }

    .toast-amount-data {
      padding: 12px 0 0 33px;
    }
  }
}

.d-flex {
  display: flex;
}

.center-content {
  justify-content: center;
}

.payment-processing-box {
  margin-top: 30px;
  width: 600px;
  display: flex;
  justify-content: center;
}

.payment-processing-content {
  background: $paleGray;
  border: 1px solid $lightCyanBlue;
  border-radius: 8px;
  width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 14px;
}

.payment-processing-text {
  width: 520px;
  text-align: center;
  line-height: 140%;
}

@media only screen and (min-device-width: 350px) and (max-device-width: 370px) {
  .tab-data-lndev {
    .MuiTabPanel-root {
      width: 323px;
    }

    .MuiTabs-root {
      width: 323px;
    }
  }

  .payment-request {
    width: 206px !important;
  }

  .scan-qr-code {
    margin: 0;
  }

  .clipboard-link-receive {
    width: 234px !important;
  }

  .amount-sent {
    width: auto;
    padding: 18px 306px 18px 15px;
  }

  .payment-processing-box {
    width: 360px !important;
  }

  .payment-processing-content {
    width: 320px !important;
  }

  .payment-processing-text {
    width: 280px !important;
  }
}

@media only screen and (min-device-width: 370px) and (max-device-width: 380px) {
  .tab-data-lndev {
    .MuiTabPanel-root {
      width: 334px;
    }

    .MuiTabs-root {
      width: 334px;
    }
  }

  .payment-request {
    width: 215px !important;
  }

  .scan-qr-code {
    margin: 0;
  }

  .amount-sent {
    width: auto;
    padding: 18px 317px 18px 15px;
  }

  .payment-processing-box {
    width: 360px !important;
  }

  .payment-processing-content {
    width: 320px !important;
  }

  .payment-processing-text {
    width: 280px !important;
  }
}

@media only screen and (min-device-width: 380px) and (max-device-width: 400px) {
  .tab-data-lndev {
    .MuiTabPanel-root {
      width: 350px;
    }

    .MuiTabs-root {
      width: 350px;

      .MuiTabs-flexContainer {
        padding-left: 90px;
      }
    }
  }

  .payment-request {
    width: 228px !important;
  }

  .clipboard-link-receive {
    width: 210px !important;
  }

  .amount-sent {
    width: auto;
    padding: 18px 0 18px 15px;
  }

  .payment-processing-box {
    width: 360px !important;
  }

  .payment-processing-content {
    width: 320px !important;
  }

  .payment-processing-text {
    width: 280px !important;
  }
}

@media only screen and (min-device-width: 420px) and (max-device-width: 480px) {
  .lndev-content {
    margin: 0;
    padding: 25% 7% 0 7%;
  }

  .tab-data-lndev {
    .MuiTabPanel-root {
      width: auto;
    }

    .MuiTabs-root {
      width: auto;

      .MuiTabs-flexContainer {
        padding-left: 90px;
      }
    }
  }

  .lndev-content-data {
    padding: 10% 0 0 0;
  }

  .payment-processing-box {
    width: 360px !important;
  }

  .payment-processing-content {
    width: 320px !important;
  }

  .payment-processing-text {
    width: 280px !important;
  }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 600px) {
  .lndev-content {
    margin: 0;
  }

  .lndev-content-data {
    padding: 10% 0 0 0;
  }

  .payment-processing-box {
    width: 400px !important;
  }

  .payment-processing-content {
    width: 360px !important;
  }

  .payment-processing-text {
    width: 320px !important;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 768px) {
  .lndev-content {
    margin: 0;
  }

  .lndev-content-data {
    padding: 10% 0 0 0;
  }

  .payment-processing-box {
    width: 400px !important;
  }

  .payment-processing-content {
    width: 360px !important;
  }

  .payment-processing-text {
    width: 320px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1025px) {
  .lndev-content {
    margin: 0;
  }

  .lndev-content-data {
    padding: 0 11%;
  }

  .payment-processing-box {
    width: 440px !important;
  }

  .payment-processing-content {
    width: 400px !important;
  }

  .payment-processing-text {
    width: 360px !important;
  }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 950px) {
  .payment-processing-box {
    width: 480px !important;
  }

  .payment-processing-content {
    width: 440px !important;
  }

  .payment-processing-text {
    width: 400px !important;
  }
}

@media only screen and (min-device-width: 950px) and (max-device-width: 1025px) {
  .payment-processing-box {
    width: 480px !important;
  }

  .payment-processing-content {
    width: 440px !important;
  }

  .payment-processing-text {
    width: 400px !important;
  }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1280px) {
  .lndev-content-data {
    padding: 0 20% !important;
  }

  .payment-processing-box {
    width: 520px !important;
  }

  .payment-processing-content {
    width: 480px !important;
  }

  .payment-processing-text {
    width: 440px !important;
  }
}

@media only screen and (min-device-width: 1280px) and (max-device-width: 1500px) {
  .lndev-content-data {
    padding: 0 20%;
  }
}